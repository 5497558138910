export enum ERROR_CODES {
    MINIMUM_AGE_REQUIRED = 101,
    EMPTY_RENTAL = 102,
    INVALID_FIRST_NAME = 103,
    INVALID_LAST_NAME = 104,
    INVALID_EMAIL = 105,
    INVALID_PHONE_NUMBER = 106,
    START_DATE_IN_PAST = 107,
    END_DATE_IN_PAST = 108,
    RENTAL_EXPIRED = 109
}