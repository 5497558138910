import { NavigationContext, Path, PaymentMethod, RentalContext, Payment as PaymentInterface, ActionButtonContext } from 'components/app.component'
import PurchaseSummary from 'components/organisms/purchase-summary/purchase-summary'
import React, { useContext, useEffect, useState } from 'react'
import { Col, RadioGroup, Row } from 'react-materialize'
import { useNavigate } from 'react-router-dom'
import { confirmRental, RentalConfirmationInfoResponse } from 'services/rentals-thin.service'
import './payment.css'

export default function Payment() {
    const { rentalContext, setRentalContext } = useContext(RentalContext)
    const { navigationContext, setNavigationContext } = useContext(NavigationContext)
    const { updateDisabled } = useContext(ActionButtonContext)
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(rentalContext.payment?.method)
    const navigation = useNavigate()

    useEffect(() => {

        async function submit(payment: PaymentInterface) {
            try {
                const response: RentalConfirmationInfoResponse = await confirmRental(payment)
                setRentalContext((current) => ({ ...current, confirmation: { confirmationCode: response.data.confirmationCode } }))
                console.log('rental successfully created', response)
                navigation(Path.Confirmation)
            } catch (error) {
                console.error('there was an error creating rental')
                setNavigationContext(Path.Payment)
                navigation(Path.Payment)
            }
        }

        if (rentalContext.payment?.method !== paymentMethod) {
            setRentalContext((current) => ({ ...current, payment: { method: paymentMethod } }))
            setNavigationContext(Path.Payment)
        }

        if (navigationContext === Path.Confirmation) {
            submit(rentalContext.payment)
        }
    }, [rentalContext, setRentalContext, paymentMethod, navigationContext, setNavigationContext, navigation])

    useEffect(()=> {
        updateDisabled(!paymentMethod)
    }, [paymentMethod, updateDisabled])

    return(
        <div className='payment-container'>
            <div>
                <h1>Método de pago</h1>
            </div>
            <label htmlFor='payment'>Selecciona el método de pago</label>
            <Row>
                <Col>
                    <RadioGroup
                        label="Metodo de pago"
                        name="payment"
                        options={[
                            {
                                label: 'Efectivo',
                                value: PaymentMethod.CASH
                            },
                            {
                                label: 'Transferencia bancaria',
                                value: PaymentMethod.TRANSFER
                            }
                        ]}
                        value={paymentMethod}
                        withGap
                        radioClassNames='radio-buttons-container'
                        onChange={(e) => setPaymentMethod(e.currentTarget.value as PaymentMethod)}
                    />
                    {paymentMethod === PaymentMethod.TRANSFER &&
                        <div>
                            <span>Datos de cuenta</span>
                            <ul className='account-details' data-testid="accountDetails">
                                <li>Banco: BBVA</li>
                                <li>No.de tarjeta: 4152 3134 3701 5089</li>
                                <li>CLABE interbancaria: 12 022 01462262444 4</li>
                                <li>No. de cuenta: 146 226 2444</li>
                                <li>Beneficiario: Meridano Rentals</li>
                            </ul>
                        </div>
                    }
                </Col>
            </Row>
            <Row className='hide-on-large-only'>
                <PurchaseSummary />
            </Row>
        </div>
    )
}