import { RentalContext } from "components/app.component";
import React, { useContext } from "react";
import { Icon, Row } from "react-materialize";
import "./confirmation.css"

export default function Confirmation() {
    const { rentalContext } = useContext(RentalContext)

    if (!rentalContext.confirmation?.confirmationCode) {
        return null;
    }

    return (
        <div>
            <div className="center-align">
                <h1>Su renta está confirmada!</h1>
            </div>
            <Row className='center-align'>
                <div>Su renta del día {rentalContext.startDate.toDateString()} al {rentalContext.endDate.toDateString()} ha sido confirmada.</div>
                <div>Código de reservación: <strong>{rentalContext.confirmation.confirmationCode}</strong></div>
                <div>Hemos enviado un correo electrónico a <strong>{rentalContext.contactInfo.email}</strong> con los detalles de su reservación.</div>
                <div>Ante cualquier duda puede contactarnos. Estamos para ayudarle!</div>
                <Icon center={true} className="confirmation-check">check_circle</Icon>
                <div>Gracias por confiar en nosotros.</div>
            </Row>
        </div>
    )
}