import axios from 'axios'
import { formatDate } from 'helpers/date-utils'
import { toggleModal } from 'components/molecules/error-modal/error-modal'
import { ConfirmationDetails, ContactInfo, Delivery, Payment, Rental, Totals } from 'components/app.component'
import { getCookie } from 'react-use-cookie'
export const API = 'https://rentals-thin.fly.dev'
export const prefix = API + '/rentals-thin-api'

axios.interceptors.request.use(
    (config) => {
        if (getCookie('rentalId')) {
            config.headers['Authorization'] = `Bearer ${getCookie('rentalId')}`;
        }
        return config;
    }
);

axios.interceptors.response.use(undefined, (error) => {
    toggleModal(true, error.response.data?.code)
    throw error;
})

export async function init(): Promise<InitResponse> {
    return await axios.post(`${prefix}/v1/init`)
}

export async function addItem(carId: string, startDate: Date, endDate: Date): Promise<UpdateRentalItemsResponse> {
    return await axios.put(`${prefix}/v1/addItem/${carId}`, { startDate: formatDate(startDate), endDate: formatDate(endDate) })
}

export async function removeItem(carId: string): Promise<UpdateRentalItemsResponse> {
    return await axios.put(`${prefix}/v1/removeItem/${carId}`)
}

export async function updateContactInfo(contactInfo: ContactInfo): Promise<UpdateContactInfoResponse> {
    return await axios.post(`${prefix}/v1/contactInfo`, contactInfo)
}

export async function updateDeliveryInfo(delivery: Delivery): Promise<UpdateDeliveryInfoResponse> {
    return await axios.post(`${prefix}/v1/delivery`, delivery)
}

export async function confirmRental(payment: Payment): Promise<RentalConfirmationInfoResponse> {
    return await axios.post(`${prefix}/v1/confirm`, payment)
}

export interface InitResponse {
    data: Rental
}

export interface UpdateRentalItemsResponse {
    data: Totals
}

export interface UpdateContactInfoResponse {
    data: ContactInfo
}

export interface UpdateDeliveryInfoResponse {
    data: Delivery
}

export interface RentalConfirmationInfoResponse {
    data: ConfirmationDetails
}