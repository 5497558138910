import { currencyFormat } from 'helpers/currency-utils'
import React, { useContext } from 'react'
import { Col, Row, Divider, Section, Icon, Button } from 'react-materialize'
import { ItemDetail, NavigationContext, Path, RentalAppContext, RentalContext } from 'components/app.component'
import './purchase-summary.css'
import ActionButton from 'components/atoms/action-button/action-button'
import { removeItem, UpdateRentalItemsResponse } from 'services/rentals-thin.service'
import { useNavigate } from 'react-router-dom'

export default function PurchaseSummary () {
  const { rentalContext, setRentalContext } = useContext(RentalContext)
  const {navigationContext} = useContext(NavigationContext)
  const navigation = useNavigate()

  async function removeFromCart(carId: string) {
    try {
      const res: UpdateRentalItemsResponse = await removeItem(carId)
      setRentalContext((current: RentalAppContext) => ({ ...current, totals: res.data }))

      if (res.data.items.length === 0 && navigationContext !== Path.Home) {
        navigation(Path.Home)
      }
    } catch (error) {
      console.error('Fail to remove item')
    }
  }

  return (
    <Row className='center-align main-border'>
      {rentalContext.totals && rentalContext.totals.items.length > 0
        ? <Section>
          <Col s={12} m={12} l={12}><h4>Resumen de compra</h4></Col>
          <Row>
            <Col s={6} m={6} l={6} className='left-align'>Días de renta</Col>
            <Col s={5} m={5} l={5} className='right-align' data-testid='rental-days'>{rentalContext.totals.days}</Col>
          </Row>
          <Divider />
          {rentalContext.totals.items.map((item: ItemDetail) => {
            return (
            <div key={item.id}>
              <Row>
                  <Col className='left-align' data-testid={`name-${item.id}`}>{item.name}</Col>
              </Row>
              <Row className='valign-wrapper'>
                <Col s={5} m={5} l={5} className='left-align' style={{ padding: '0 0 0 5%' }}>Precio por día</Col>
                  <Col s={6} m={6} l={6} className='right-align' data-testid={`price-${item.id}`}>{currencyFormat(item.price)}</Col>
                  <Col s={1} m={1} l={1} style={{ padding: 0 }}>
                    <Button tooltip='Eliminar' data-position="left" data-testid={`remove-${item.id}`} flat={true} icon={<Icon>delete_forever</Icon>} onClick={() => removeFromCart(item.id)}></Button>
                  </Col>
              </Row>
            </div>
            )
          })}
          <Divider />
          <Row>
            <Col s={5} m={5} l={5} className='left-align' style={{ padding: '0 0 0 5%' }}><span>Total</span></Col>
            <Col s={6} m={6} l={6} className='right-align' data-testid='amount-due'><span><strong>{currencyFormat(rentalContext.totals.amountDue)}</strong></span></Col>
          </Row>
          <Row>
            <Col s={12} m={12} l={12} className='center-align'>
              <ActionButton testid='set-rental-btn' className='purchase-summary-btn'/>
            </Col>
          </Row>
          </Section>
        : <Section><h2 className='flow-text'>No ha seleccionado ningún auto</h2></Section>}
    </Row>
  )
}
