import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Card, CardTitle, Icon } from 'react-materialize'
import { RentalAppContext, RentalContext } from 'components/app.component'
import { Car } from 'services/cars-thin.service'
import classNames from 'classnames';
import { currencyFormat } from 'helpers/currency-utils';
import './vehicle-card.component.scss';
import { addItem, removeItem, UpdateRentalItemsResponse } from 'services/rentals-thin.service';

export interface CarCardProps {
  car: Car
}

export default function VehicleCard ({ car }: CarCardProps) {
  const [isAdded, setIsAdded] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const { rentalContext, setRentalContext } = useContext(RentalContext)

  const updateCar = async (carId: string) => {
    let res: UpdateRentalItemsResponse;
    setIsUpdating(true)

    if (isAdded) {
      res = await removeItem(carId)
    } else {
      res = await addItem(carId, rentalContext.startDate, rentalContext.endDate)
    }
    setRentalContext((current: RentalAppContext) => ({ ...current, totals: res.data }))
    setIsUpdating(false)
  }

  const {brand, model, manufacturingYear, pricePerDay, passengers, transmission, bags} = car;

  useEffect(()=>{
    if (rentalContext.totals) {
      const isItemInCart: boolean = !!rentalContext.totals.items.find((item) => item.id === car.id) 
      setIsAdded(isItemInCart)
    }
  }, [rentalContext.totals, setIsAdded, car.id])

  return (
    <Col
      l={6}
      m={12}
      s={12}
    >
      <Card
        actions={[
          <Button
            key={`btn-${car.id}`}
            className={classNames('add-button',{'remove' : isAdded})}
            disabled={isUpdating}
            onClick={() => updateCar(car.id)}><Icon>{isAdded ? 'cancel' : 'add'}</Icon><span>{isAdded ? 'Remover' : 'Agregar'}</span></Button>
        ]}
        className='vehicle-card'
        closeIcon={<Icon>close</Icon>}
        header={<CardTitle image={`https://assets.rentas.palmota.mx/${car.id}.png`}/>}
        horizontal
        revealIcon={<Icon>more_vert</Icon>}
      >
        <div className='vehicle-description'>
          <span className='vehicle-title' data-testid='vehicle-title'>{`${brand} ${model} ${manufacturingYear}`}</span>
          <span className='vehicle-feat-left' data-testid='passengers'><Icon>people</Icon>{passengers}</span>
          <span className='vehicle-feat-right' data-testid='transmission'><Icon>nature</Icon>{transmission}</span>
          <span className='vehicle-feat-left' data-testid='bags'><Icon>card_travel</Icon>{bags}</span>
          <span className='vehicle-feat-right vehicle-price'><strong data-testid='pricePerDay'>{currencyFormat(pricePerDay)}</strong>/día</span>
        </div>
      </Card>
    </Col>
  )
}
