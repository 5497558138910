/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { DatePicker } from 'react-materialize'
import 'materialize-css/dist/css/materialize.min.css'
import M from 'materialize-css'
import './date-picker.css'
import dayjs from 'dayjs'

export interface StartDatePickerProps {
    /**
       * ID of the date picker
       */
    id: string
    /**
       * Callback when start date is selected
       */
    updatedDate: (date: Date) => void
    /**
     * Text that is gonna be displayed above the input
     */
    label: string
    /**
     * Minimun date. If not provided defaults to todays date
     */
    minDate?: Date
}

export default function StartDatePicker({
    id,
    updatedDate,
    label,
    minDate
}: StartDatePickerProps) {
    const today: Date = dayjs().startOf("day").toDate()

    useEffect(() => {
        M.Datepicker.init(document.getElementById(`${id}`), {
            format: 'dd-mm-yyyy',
            onSelect: function (this: M.Datepicker, date: Date) {
                updatedDate(date)
                this.setInputValue()
            },
            onOpen: function (this: M.Datepicker) {
                this.options.minDate = minDate || today
            },
            onClose: function (this: M.Datepicker) {
                updatedDate(this.date)
                this.setInputValue()
            },
            i18n: {
                done: 'Aceptar',
                months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                weekdaysAbbrev: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
                cancel: "",
                monthsShort: ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'],
                weekdaysShort: ['Dom','Lun','Mar','Mie','Jue','Vie','Sab']
            },
            yearRange: [today.getFullYear(), today.getFullYear() + 10]
        })

    }, [minDate])

    return (
        <div className='date-input-container'>
            <span className='col'>{label}</span>
            <DatePicker id={id} />
        </div>
    )
};
