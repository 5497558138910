import { ActionButtonContext, NavigationContext, Path } from 'components/app.component'
import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-materialize'
import { useLocation } from 'react-router-dom'

export interface ActionButtonProps {
    testid?: string;
    className?: string;
}

export default function ActionButton({ testid, className }: ActionButtonProps) {
    const { setNavigationContext } = useContext(NavigationContext)
    const { disabled } = useContext(ActionButtonContext)
    const location = useLocation()
    const [buttonText, setButtonText] = useState("")
    const [nextLocation, setNextLocation] = useState("")

    useEffect(() => {
        switch (location.pathname) {
            case Path.Home: {
                setNavigationContext(Path.Home);
                setButtonText('Continuar');
                setNextLocation(Path.Contact)
                break;
            }
            case Path.Contact: {
                setNavigationContext(Path.Contact);
                setButtonText('Continuar');
                setNextLocation(Path.Delivery);
                break;
            }
            case Path.Delivery: {
                setNavigationContext(Path.Delivery);
                setButtonText('Continuar');
                setNextLocation(Path.Payment);
                break;
            }
            case Path.Payment: {
                setNavigationContext(Path.Payment);
                setButtonText('Agendar renta');
                setNextLocation(Path.Confirmation);
                break;
            }
        }
    }, [location, setNavigationContext])
    
    if (location.pathname === Path.Confirmation){
        return null;
    }

    return(
        <Button className={className} data-testid={testid} disabled={disabled} onClick={() => setNavigationContext(nextLocation)}>{buttonText}</Button> 
        )
    }
    