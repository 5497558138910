import axios from 'axios'
import { formatDate } from '../helpers/date-utils'
export const API = 'https://cars-thin.fly.dev'
export const prefix = API + '/cars-thin-api'

export async function getAvailableCars (startDate: Date, endDate: Date): Promise<AvailableCarsResponse> {
  return await axios.get(`${prefix}/v1/available`, {params: {startDate: formatDate(startDate), endDate: formatDate(endDate)}})
}

export interface AvailableCarsResponse {
  data: Car[]
}

export interface Car {
  id: string
  plateNumber: string
  serialNumber: string
  color: string
  mileage: number
  model: string
  brand: string
  manufacturingYear: number
  transmission: string
  engineType: string
  octanes: string
  fuelType: string
  serviceRange: number
  tireSize: number
  passengers: number
  cylinders: number
  doors: number
  pricePerDay: number
  agencyId: string
  status: string
  bags: number
}
