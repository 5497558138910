import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-materialize";
import { useNavigate } from "react-router-dom";
import { Path } from "components/app.component";
import { ERROR_CODES } from ".";

export let toggleModal = (display: boolean, errorCode: number) => {};

function ErrorModalComponent() {
    const [display, setDisplay] = useState(false)
    const [message, setMessage] = useState("")
    const [errorCode, setErrorCode] = useState(null)
    const [title, setTitle] = useState("")
    const navigation = useNavigate()

    toggleModal = (display: boolean, errorCode: number) => {
        setDisplay(display)
        setErrorCode(errorCode)

        switch(errorCode) {
            case ERROR_CODES.MINIMUM_AGE_REQUIRED:
                setTitle("Edad mínima requerida")
                setMessage("La edad mínima es 16 años.")
                break;
            case ERROR_CODES.EMPTY_RENTAL:
                setTitle("Error en reservación")
                setMessage("La reservación se encuentra vacía.")
                break;
            case ERROR_CODES.INVALID_FIRST_NAME:
                setTitle("Nombre inválido")
                setMessage("El nombre no cuenta con el formato adecuado.")
                break;
            case ERROR_CODES.INVALID_LAST_NAME:
                setTitle("Apellido inválido")
                setMessage("El apellido no cuenta con el formato adecuado.")
                break;
            case ERROR_CODES.INVALID_EMAIL:
                setTitle("Email inválido")
                setMessage("El email no cuenta con el formato adecuado.")
                break;
            case ERROR_CODES.INVALID_PHONE_NUMBER:
                setTitle("Teléfono inválido")
                setMessage("El teléfono no cuenta con el formato adecuado.")
                break;
            case ERROR_CODES.START_DATE_IN_PAST:
                setTitle("Fecha de inicio no válida")
                setMessage("La fecha de inicio se encuentra en el pasado.")
                break;
            case ERROR_CODES.END_DATE_IN_PAST:
                setTitle("Fecha de devolución no válida")
                setMessage("La fecha de devolución debe ser posterior a la de inicio.")
                break;
            case ERROR_CODES.RENTAL_EXPIRED:
                setTitle("Reservación expirada")
                setMessage("El tiempo de espera para confirmar su reservación ha expirado. Favor de iniciar nuevamente.")
                break;
            default:
                setTitle("Error")
                setMessage("Lo sentimos! Algo ha salido mal. Favor de intentarlo nuevamente.")
                break;
        }
    }

    useEffect(()=>{
        if (display) {
            return;
        }

        switch (errorCode) {
            case ERROR_CODES.RENTAL_EXPIRED:
                navigation(Path.Home)
                window.location.reload()
                break;
            default:
                break;
        }
    }, [display, errorCode, navigation])

    return(
        <Modal
            open={display}
            data-testid="errorModal"
            id={"errorModal"}
            options={{ onCloseEnd: () => setDisplay(false) }}
            actions={[<Button flat modal="close" node="button" className="red-text text-darken-2">Cerrar</Button>]}
        >
            <h4 className="red-text text-darken-2">
                {title}
            </h4>
            <p>
                {message}
            </p>
        </Modal>
    );
}

export default ErrorModalComponent;