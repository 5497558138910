import React, { useState, useContext, useEffect } from 'react'
import PurchaseSummary from 'components/organisms/purchase-summary/purchase-summary'
import { Row, Col, Button, Icon, Navbar } from 'react-materialize'
import { RentalContext, NavigationContext } from 'components/app.component'
import './header-summary.css'
import ActionButton from 'components/atoms/action-button/action-button'
import ErrorModalComponent from 'components/molecules/error-modal/error-modal'
import { Outlet } from 'react-router-dom'
import StepsBreadcrumb from 'components/molecules/breadcrumb/steps-breadcrumb'

export default function HeaderSummary () {
  const [displaySummary, setDisplaySummary] = useState(false)
  const { rentalContext } = useContext(RentalContext)
  const { navigationContext } = useContext(NavigationContext)
  
  useEffect(() => {
    setDisplaySummary(false)
  }, [navigationContext])
  
  useEffect(() => {
    if (rentalContext.totals?.items.length === 0) {
      setDisplaySummary(false)
    }
  }, [rentalContext])

  return (
    <Row>
      <ErrorModalComponent></ErrorModalComponent>
      <Row>
        <Navbar
          alignLinks="right"
          brand={<a className="brand-logo " href="/"><h5>Rentadora Mérida</h5></a>}
          id="mobile-nav"
          menuIcon={<span></span>}
          options={{
            draggable: false,
            edge: 'left',
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: null,
            onOpenEnd: null,
            onOpenStart: null,
            outDuration: 200,
            preventScrolling: true
          }}
        >
        </Navbar>
      </Row>
      <Row>
        <Col s={12} m={12} l={9}>
          <StepsBreadcrumb></StepsBreadcrumb>
          <Outlet />
        </Col>
        {displaySummary
          ? <Button data-testid="close-summary-top" className='hide-on-large-only close-icon' onClick={() => setDisplaySummary(false)}><Icon>close</Icon></Button>
          : <Button data-testid="open-summary-top" className='hide-on-large-only open-icon' onClick={() => setDisplaySummary(true)}><Icon>directions_car</Icon>
            {rentalContext.totals && <sup key="counter">{rentalContext.totals.items.length}</sup>}
          </Button>}
        <Col data-testid="purchase-container" s={12} m={12} l={3} className={'purchase-container '+(displaySummary ? 'show-summary' : '')}>
          <PurchaseSummary />
        </Col>
      </Row>
      <Row className='continue-bottom'>
        {displaySummary ? 
        <Button data-testid="close-summary-bottom" className='hide-on-large-only close-bottom' onClick={() => setDisplaySummary(false)}>Cerrar</Button>
        : <ActionButton className='hide-on-large-only continue-bottom' />}
      </Row>
    </Row>
  )
}
