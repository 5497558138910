import { ActionButtonContext, ContactInfo, NavigationContext, Path, RentalContext } from 'components/app.component'
import React, { useContext, useEffect } from 'react'
import { TextInput, Col, Row, Checkbox } from 'react-materialize'
import PhoneInput from 'react-phone-input-2'
import { useNavigate } from 'react-router-dom'
import { updateContactInfo, UpdateContactInfoResponse } from 'services/rentals-thin.service'
import './contact-information.css'
import 'react-phone-input-2/lib/style.css'
import { useFormik } from 'formik';
import * as Yup from 'yup';

export default function ContactInformation() {
    const navigation = useNavigate()
    const { navigationContext, setNavigationContext } = useContext(NavigationContext)
    const { rentalContext, setRentalContext } = useContext(RentalContext)
    const { updateDisabled } = useContext(ActionButtonContext)

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(1)
            .max(150, 'Nombre muy largo')
            .matches(/^[a-zA-Z\s]+$/, "Nombre incorrecto")
            .required('Campo obligatorio'),
        lastName: Yup.string()
            .min(1)
            .max(150, 'Apellido muy largo')
            .matches(/^[a-zA-Z\s]+$/, "Apellido incorrecto")
            .required('Campo obligatorio'),
        email: Yup.string()
            .min(1)
            .email('Email incorrecto')
            .required('Campo obligatorio'),
        phone: Yup.string()
            .min(1)
            .min(15, 'Telefono incorrecto')
            .required('Campo obligatorio'),
        birthDate: Yup.string()
            .min(1)
            .required(),
        dlCheck: Yup
            .boolean()
            .required()
            .oneOf([true], 'Debe contar con una credencial vigente.')
    });

    const formik = useFormik({
        initialValues: { firstName: "", lastName: "", email: "", phone: "", birthDate: "", dlCheck: false},
        onSubmit,
        validationSchema
    })

    // This is triggered after executing handleSubmit. See documentation https://formik.org/docs/api/useFormik
    async function onSubmit (contactInfo: ContactInfo) {
        try {
            const response: UpdateContactInfoResponse = await updateContactInfo(contactInfo);
            setRentalContext((current) => ({ ...current, contactInfo: response.data }));
            navigation(Path.Delivery)
        } catch (error) {
            console.error('there was an error updating contact info')
            setNavigationContext(Path.Contact)
        }
    }

    useEffect(() => {
        if (formik.dirty) {
            updateDisabled(!formik.isValid)
        } else {
            updateDisabled(true)
            if (rentalContext.contactInfo) {
                formik.setValues(rentalContext.contactInfo)
            }
        }
    })

    useEffect(() => {
        if (navigationContext === Path.Delivery && !formik.isSubmitting) {
            formik.handleSubmit();
        }
    }, [navigationContext, formik])

    return(
        <div>
            <div>
                <h1>Información de contacto</h1>
            </div>
            <form id="contactInfoForm">
            <Row>
                <Col s={12} m={12} l={6}>
                    <TextInput
                        id="firstName"
                        name="firstName"
                        data-length="150"
                        label="Nombre(s)"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        inputClassName={formik.errors.firstName ? 'invalid' : ''}
                    />
                </Col>
                <Col s={12} m={12} l={6}>
                    <TextInput
                        id="lastName"
                        name="lastName"
                        data-length="150"
                        label="Apellido(s)"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        inputClassName={formik.errors.lastName ? 'invalid' : ''}
                    />
                </Col>
            </Row>
            <Row>
                <Col s={12} m={12} l={6}>
                    <TextInput
                        email
                        data-length="150"
                        validate
                        id="email"
                        label="Correo electrónico"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                </Col>
                <Col s={12} m={12} l={6}>
                    <div className='input-field col'>
                        <label htmlFor='birthDate' id='birthDateLabel' data-testid='birthDateLabel'>Fecha de Nacimiento (mes/día/año)</label>
                        <input placeholder='Fecha de nacimiento' type="date" id="birthDate" value={formik.values.birthDate} onChange={formik.handleChange}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col s={12} m={12} l={6}>
                    <label htmlFor="phone">Teléfono</label>
                    <PhoneInput
                        containerStyle={{ display: 'flex', flexDirection: 'row-reverse', width: '97%'}}
                        country={'mx'}
                        value={formik.values.phone.replace(/\s/g, "")}
                        onChange={(_,__,e) => formik.handleChange(e)}
                        preferredCountries={['mx', 'us']}
                        placeholder="Teléfono"
                        inputProps={{ id: 'phone', name: 'phone' }}
                        inputClass={formik.errors.phone ? 'invalid' : ''}
                    />
                </Col>
            </Row>
            <Row>
                <Col s={12} m={12} l={6} style={{ marginBottom: '20%' }}>
                        <Checkbox
                            value={formik.values.dlCheck?.toString()}
                            onChange={formik.handleChange}
                            id='dlCheck'
                            label='Cuento con licencia de conducir oficial vigente.'></Checkbox>
                    <br/>
                    <sub>La licencia deberá ser presentada en recepción</sub>
                </Col>
            </Row>
            </form>
        </div>
    )
}