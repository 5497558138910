import React, { useState, useEffect } from 'react'
import Home from 'components/pages/home/home.component'
import { Routes, Route } from 'react-router-dom'
import { Car } from 'services/cars-thin.service'
import ContactInformation from 'components/pages/contact-information/contact-information'
import DeliveryInformation from 'components/pages/delivery-information/delivery-information'
import { init, InitResponse } from 'services/rentals-thin.service'
import { setCookie } from 'react-use-cookie'
import PaymentComponent from 'components/pages/payment/payment'
import ConfirmationComponent from 'components/pages/confirmation/confirmation'
import HeaderSummary from 'components/organisms/header-summary/header-summary.component'

export interface Rental {
  rentalId: string
  startDate: Date
  endDate: Date
  totals: Totals
  contactInfo: ContactInfo
  delivery: Delivery
  payment: Payment
  confirmation: ConfirmationDetails
}

export interface Payment {
  method: PaymentMethod
}

export enum PaymentMethod {
  CASH = 'cash',
  TRANSFER = 'transfer'
}

export interface Delivery {
  location: DeliveryLocation
  address?: string
}

export enum DeliveryLocation {
  AIRPORT = 'airport',
  AGENCY = 'agency',
  OTHER = 'other'
}

export interface ContactInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  birthDate: string;
  dlCheck?: boolean;
}

export interface RentalAppContext extends Rental {
  availableCars?: Car[]
}

export interface ItemDetail {
  id: string
  name: string
  price: number
}

export interface Totals {
  items: ItemDetail[]
  days: number
  amountDue: number
}

export interface ConfirmationDetails {
  confirmationCode: string;
}

export interface ProviderContext {
  rentalContext: RentalAppContext
  setRentalContext: React.Dispatch<React.SetStateAction<RentalAppContext>>
}

export interface NavigationProviderContext {
  navigationContext: string;
  setNavigationContext: React.Dispatch<React.SetStateAction<string>>;
}
export interface ActionButtonProviderContext {
  disabled: boolean;
  updateDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialState: RentalAppContext = {
  rentalId: '',
  startDate: null,
  endDate: null,
  availableCars: [],
  totals: {
    items: [],
    days: 0,
    amountDue: 0
  },
  contactInfo: null,
  delivery: {
    location: null
  },
  payment : {
    method: null
  },
  confirmation: {
    confirmationCode: ""
  }
}

export enum Path {
  Home = "/",
  Contact = "/contact",
  Delivery = "/delivery",
  Payment = "/payment",
  Confirmation = "/confirmation"
}

export const RentalContext = React.createContext({} as ProviderContext)
export const NavigationContext = React.createContext({} as NavigationProviderContext);
export const ActionButtonContext = React.createContext({} as ActionButtonProviderContext)

export default function App () {
  const [rentalContext, setRentalContext] = useState(initialState)
  const [navigationContext, setNavigationContext] = useState("")
  const [disabled, updateDisabled] = useState(false)

  const initialRentalValue: ProviderContext = {rentalContext, setRentalContext}
  const initialNavigationValue: NavigationProviderContext = { navigationContext, setNavigationContext }
  const initialActionButtonValue: ActionButtonProviderContext = { disabled, updateDisabled }

  useEffect(() => {
    init().then((response: InitResponse) => {
      setRentalContext(response.data)
      setCookie('rentalId', response.data.rentalId, {days: 1})
    })
  }, [setRentalContext])

  return (
    <RentalContext.Provider value={initialRentalValue}>
      <NavigationContext.Provider value={initialNavigationValue}>
        <ActionButtonContext.Provider value={initialActionButtonValue}>
          <Routes>
            <Route path={Path.Home} element={<HeaderSummary />}>
              <Route index element={<Home />} />
              <Route path={Path.Contact} element={<ContactInformation />} />
              <Route path={Path.Delivery} element={<DeliveryInformation />} />
              <Route path={Path.Payment} element={<PaymentComponent />} />
              <Route path={Path.Confirmation} element={<ConfirmationComponent />} />
              <Route path="*" element={<div>La página que busca no existe</div>}/>
            </Route>
          </Routes>
        </ActionButtonContext.Provider>
      </NavigationContext.Provider>
    </RentalContext.Provider>
  )
}
