import { Path, RentalContext } from "components/app.component";
import React, { useContext } from "react";
import { Breadcrumb, Button } from "react-materialize";
import './breadcrumb.css'

export default function StepsBreadcrumb() {
    const { rentalContext } = useContext(RentalContext)

    return (
        <Breadcrumb className="teal darken-1" cols={12}>
            <Button
                flat={true}
            >
                <a href={Path.Home}>
                    Elegir
                </a>
            </Button>
            <Button
                disabled={!(rentalContext.totals?.items.length > 0)}
                flat={true}
            >
                <a href={Path.Contact}>
                    Contacto
                </a>
            </Button>
            <Button
                disabled={!rentalContext.contactInfo?.email}
                flat={true}
            >
                <a href={Path.Delivery}>
                    Entrega
                </a>
            </Button>
            <Button
                disabled={!rentalContext.delivery?.location}
                flat={true}
            >
                <a href={Path.Payment}>
                    Pago
                </a>
            </Button>
        </Breadcrumb>
    )
}