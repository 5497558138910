import React, { useState, useEffect, useContext } from 'react'
import { Col, RadioGroup, Row, TextInput } from 'react-materialize'
import { ActionButtonContext, Delivery, DeliveryLocation, NavigationContext, Path, RentalContext } from 'components/app.component'
import { updateDeliveryInfo, UpdateDeliveryInfoResponse } from 'services/rentals-thin.service'
import { useNavigate } from 'react-router-dom'
import './delivery-information.css'

export default function DeliveryInformation() {
    const navigation = useNavigate()
    const { rentalContext, setRentalContext } = useContext(RentalContext)
    const { navigationContext, setNavigationContext } = useContext(NavigationContext)
    const { updateDisabled } = useContext(ActionButtonContext)
    const [location, setLocation] = useState<DeliveryLocation>(rentalContext.delivery?.location)
    const [address, setAddress] = useState<string>(rentalContext.delivery?.address)

    useEffect(()=>{
        setLocation(rentalContext.delivery?.location)
        setAddress(rentalContext.delivery?.address)
    }, [rentalContext])

    useEffect(() => {
        async function submit(delivery: Delivery) {
            try {
                const response: UpdateDeliveryInfoResponse = await updateDeliveryInfo(delivery)
                setRentalContext((current) => ({ ...current, delivery: response.data }));
                navigation(Path.Payment)
            } catch (error) {
                console.error('there was an error updating delivery info')
                setNavigationContext(Path.Contact)
            }
        }

        if (navigationContext === Path.Payment) {
            if (location === DeliveryLocation.OTHER && !address) {
                // TODO: display an error
                setNavigationContext(Path.Contact)
                return;
            }

            submit({ location, address: location === DeliveryLocation.OTHER ? address : undefined})
        }
    }, [navigationContext, location, address, setNavigationContext, navigation, setRentalContext])

    useEffect(()=> {
        const disabledButtonStatus = location === DeliveryLocation.OTHER ? !address : !location  
        updateDisabled(disabledButtonStatus)
    }, [location, address, updateDisabled])

    return(
        <div>
            <div>
                <h1>Lugar de entrega</h1>
            </div>
            <label htmlFor='delivery'>Selecciona el lugar de preferencia para la entrega del vehículo</label>
            <Row>
                <Col>
                    <RadioGroup
                        label="Lugar de entrega"
                        name="delivery"
                        options={[
                            {
                                label: 'Aeropuerto internacional de Mérida',
                                value: DeliveryLocation.AIRPORT
                            },
                            {
                                label: 'Oficinas de la agencia',
                                value: DeliveryLocation.AGENCY
                            },
                            {
                                label: 'Domicilio particular',
                                value: DeliveryLocation.OTHER
                            }
                        ]}
                        value={location}
                        withGap
                        radioClassNames='radio-buttons-container'
                        onChange={(e) => setLocation(e.currentTarget.value as DeliveryLocation)}
                    />
                    {location === DeliveryLocation.OTHER &&
                        <TextInput
                            id="address"
                            label="Ingresar dirección del domicilio"
                            onChange={(e)=>setAddress(e.currentTarget.value)}
                            value={address}
                            inputClassName={location === DeliveryLocation.OTHER && !address ? 'invalid' : ''}
                        />}
                    <span>Nota: el lugar seleccionado para la entrega será el mismo para la devolución</span>
                </Col>
            </Row>
        </div>
    )
}