import React, { useContext, useEffect, useState } from 'react'
import { Button, Row, Preloader, Section } from 'react-materialize'
import { RentalContext, NavigationContext, Path, ActionButtonContext } from 'components/app.component'
import { AvailableCarsResponse, Car, getAvailableCars } from 'services/cars-thin.service'
import VehicleCardComponent from 'components/organisms/vehicle-card/vehicle-card.component'
import DatePicker from 'components/molecules/date-picker/date-picker'
import './home.css'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

export default function Home () {
  const { rentalContext, setRentalContext } = useContext(RentalContext)
  const { navigationContext } = useContext(NavigationContext)
  const { updateDisabled } = useContext(ActionButtonContext)
  const [showLoader, setShowLoader] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [endDateMinDate, setEndDateMinDate] = useState(null)
  const navigation = useNavigate()

  const searchForAvailableCars = async () => {
    if (startDate && endDate) {
      setShowLoader(true)
      try {
        const response: AvailableCarsResponse = await getAvailableCars(startDate, endDate)
        setRentalContext((current) => ({ ...current, availableCars: response.data, startDate, endDate }))
        setShowLoader(false)
      } catch (err) {
        setShowLoader(false)
      }
    }
  }

  useEffect(() => {
    if (navigationContext === Path.Contact && rentalContext.totals?.items.length > 0) {
      navigation(Path.Contact)
    }
  }, [navigationContext, navigation, rentalContext.totals]);

  useEffect(() => {
    if (rentalContext.totals?.items.length > 0) {
      updateDisabled(false)
      return;
    }

    updateDisabled(true)
  }, [rentalContext.totals, updateDisabled]);

  useEffect(() => {
    if (startDate) {
      setEndDateMinDate(dayjs(startDate).add(1, "day").toDate())
    }
  }, [startDate, setEndDateMinDate])

  return (
    <div>
      <div className='date-container'>
        <h1>Reservar auto</h1>
        <DatePicker
          id='startDate'
          updatedDate={setStartDate}
          label="Fecha de inicio"
        />
        <DatePicker
          id='endDate'
          updatedDate={setEndDate}
          label="Fecha de entrega"
          minDate={endDateMinDate}
        />
        <Button disabled={!(startDate && endDate)} onClick={searchForAvailableCars}>Buscar autos disponibles</Button>
      </div>
      <Row>
        <Section>
          {showLoader && <div className='center-align'><Preloader active color='blue' flashing={false} size='big' /></div>}
          {rentalContext.availableCars?.map((car: Car) => <VehicleCardComponent car={car} />)}
        </Section>
      </Row>
    </div>
  )
}
